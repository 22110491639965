@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Made';
    font-style: normal;
    font-display: swap;
    src: url('/fonts/MADE-outer-sans.woff2') format('woff2');
}

@font-face {
    font-family: 'Imprima';
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Imprima-Regular.woff') format('woff');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    @apply scroll-smooth bg-gradient-radial from-bg-gradient-light to-bg-gradient-dark;
    animation: bgGlow 5s linear infinite;
}

body {
    @apply h-full bg-gradient-radial from-bg-gradient-light to-bg-gradient-dark overscroll-y-contain;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

h1, h2 {
    @apply text-title md:text-title-md lg:text-title-lg leading-title tracking-tighter prose font-title text-glow;
    animation: flicker 0.1s linear infinite;
}

h3 {
    @apply lg:text-heading-lg md:text-heading-md text-heading leading-title tracking-tight prose font-title text-glow;
    animation: flicker 0.1s linear infinite;
}

p {
    @apply text-white font-sans text-base lg:text-base-lg my-4 text-glow;
    animation: flicker 0.1s linear infinite;
}

button {
    @apply relative z-40;
}

.section {
    @apply flex flex-col lg:flex-row max-w-desktop lg:place-items-center px-4 md:px-16 lg:px-16;
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


.caption {
    @apply uppercase text-white text-caption;
}

.background {
    @apply z-50 w-screen h-full fixed top-0 bg-blend-multiply pointer-events-none;
    background-image: url('../public/images/overlay.webp');
}

.text-glow {
    @apply text-white;
    filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.25)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.25));
    animation: animateGlow 6s infinite;
}



@keyframes flicker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.92;
    }
}

@keyframes bgGlow {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.98;
    }
}


@keyframes animateGlow {
    0% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.25)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.25));
    }
    10% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.19)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.19));
    }
    13% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.23)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.23));
    }

    15% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.16)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.16));
    }
    18% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.22)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.22));
    }
    20% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.08)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.08));
    }
    45% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.24)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.24));
     }
    55% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.2)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.2));
    }
    70% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.18)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.18));
    }
    82% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.22)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.22));
    }
    84% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.17)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.17));
    }
    86% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.21)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.21));
    }
    90% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.17)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.17));
     }
    100% {
        filter: drop-shadow(0 3px 11px rgba(255, 255, 255, 0.25)) drop-shadow(0 3px 11px rgb(255 255 255 / 0.25));
    }
  }
